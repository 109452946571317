import icon_deposit from "./img/deposit.svg"
import icon_cashback from "./img/cashback.svg"
import icon_cash_withdraw from "./img/cash_withdraw.svg"
import icon_bag from "./img/bag.svg"

export const ICONS = {
  icon_deposit,
  icon_cashback,
  icon_cash_withdraw,
  icon_bag,
}

export const positions = [
  {
    xs: {
      left: -160,
      afterRight: "4em",
    },
    sm: {
      left: -100,
      afterRight: "8.5em",
    },
    md: {
      left: -100,
      afterRight: "8.8em",
    },
    lg: {
      left: -103,
      afterRight: "8.5em",
    },
  },
  {
    xs: {
      left: -160,
      afterRight: "4em",
    },
    sm: {
      left: -100,
      afterRight: "8.5em",
    },
    md: {
      left: -100,
      afterRight: "8.8em",
    },
    lg: {
      left: -103,
      afterRight: "8.5em",
    },
  },
  {
    xs: {
      left: -117,
      afterRight: "4em",
    },
    sm: {
      left: -80,
      afterRight: "8.5em",
    },
    md: {
      left: -78,
      afterRight: "8.8em",
    },
    lg: {
      left: -82,
      afterRight: "8.5em",
    },
  },
  {
    xs: {
      left: -160,
      afterRight: "4em",
    },
    sm: {
      left: -100,
      afterRight: "8.5em",
    },
    md: {
      left: -100,
      afterRight: "8.8em",
    },
    lg: {
      left: -103,
      afterRight: "8.5em",
    },
  },
]
