import React, { ReactElement } from "react"

import { withStyles } from "@material-ui/styles"
import { Theme, Zoom } from "@material-ui/core"
import Tooltip from "@material-ui/core/Tooltip"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import { decode } from "html-entities"
import clsx from "clsx"
import hint_icon from "../../../images/icons/icon_hint.svg"
import close_icon from "../../../images/icons/icon_close.svg"

import * as styles from "../conditions.module.scss"
import { ICONS } from "../helpers"

type ConditionsItem = {
  img: keyof typeof ICONS
  title: string
  desc: ReactElement | string
  hint: string
  position: Record<string, any>
}

export function ConItemWithTooltip({ img, title, desc, hint, position }: ConditionsItem) {
  const [open, setOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      left: position!.xs.left,
      color: "#000",
      position: "absolute",
      pointerEvents: "auto",
      cursor: "default",
      padding: "24px 16px",
      background: "#ffffff",
      border: "1px solid #cdcdcd",
      boxSizing: "border-box",
      boxShadow: " 0px 8px 14px rgba(122, 122, 122, 0.25)",
      borderRadius: 8,
      width: 250,
      transition: " 0.3s all",
      marginTop: 0,

      "&::after": {
        right: position!.xs.afterRight,
        borderTop: "none",
        content: "''",
        position: "absolute",
        bottom: "99%",
        border: "0.75rem solid transparent",
        borderBottomColor: "#ffffff",
        filter: "drop-shadow(0 -0.0425rem 0 rgba(122, 122, 122, 0.3))",
      },

      [theme.breakpoints.up("sm")]: {
        left: position!.sm.left,
        "&::after": {
          right: position!.sm.afterRight,
        },
      },

      [theme.breakpoints.up("md")]: {
        left: position!.md.left,
        marginTop: 16,
        "&::after": {
          right: position!.md.afterRight,
        },
      },

      [theme.breakpoints.up("lg")]: {
        left: position!.lg.left,
        "&::after": {
          right: position!.lg.afterRight,
        },
      },
    },
  }))(Tooltip)
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <HtmlTooltip
          PopperProps={{
            disablePortal: false,
          }}
          onClose={handleTooltipOpen}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <>
              <div className={styles.hintModal}>{decode(hint)}</div>
              <div className={styles.close} onClick={handleTooltipClose} aria-hidden="true">
                <img src={close_icon} alt="close" />
              </div>
            </>
          }
          TransitionComponent={Zoom}
        >
          <div className={styles.hintContainer} onClick={handleTooltipOpen} aria-hidden="true">
            <li className={styles.itemBlock}>
              <span className={styles.img}>
                <img alt="Невероятные условия" src={ICONS[img]} />
              </span>
              <div>
                <h4 className={styles.title}>{title}</h4>
                <p className={clsx(styles.desc, styles.hintDesc)}>
                  {desc} <img src={hint_icon} alt="hint" />
                </p>
              </div>
            </li>
          </div>
        </HtmlTooltip>
      </div>
    </ClickAwayListener>
  )
}
