import React, { ReactElement } from "react"

import * as styles from "../conditions.module.scss"
import { ICONS } from "../helpers"

type ConditionsItem = {
  img: keyof typeof ICONS
  title: string
  desc: ReactElement | string
}

export const ContItem = ({ img, title, desc }: ConditionsItem) => (
  <li className={styles.itemBlock}>
    <span className={styles.img}>
      <img alt="Невероятные условия" src={ICONS[img]} />
    </span>
    <div>
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.desc}>{desc}</p>
    </div>
  </li>
)
