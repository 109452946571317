// extracted by mini-css-extract-plugin
export var close = "conditions-module--close--4nfWl";
export var desc = "conditions-module--desc--35if5";
export var head = "conditions-module--head--zfBL6";
export var hintContainer = "conditions-module--hintContainer--EXAd8";
export var hintDesc = "conditions-module--hintDesc--V-UhL";
export var hintModal = "conditions-module--hintModal--XWxWs";
export var img = "conditions-module--img--4Xgie";
export var itemBlock = "conditions-module--itemBlock--ADkht";
export var root = "conditions-module--root--3yS-H";
export var title = "conditions-module--title--YpWIQ";